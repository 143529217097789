import React, { useState, useEffect, Fragment } from "react";

import { dataSecure } from "../DataFiles/standardText";

import "../MainForm/MainForm.css";
import {
  stateNameToAbbreviationMap,
  statesFullNameList,
} from "../DataFiles/states";

import PhoneVerificationInput from "./PhoneVerificationInput";

const DemographicForm = (props) => {
  const { onFormValidated, setValidated, headerRef } = props;

  const [localState, setLocalState] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  });

  const [zipLength, setZipLength] = useState(0);
  const [zipError, setZipError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isPhoneValidated, setIsPhoneValidated] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);

  const handleChange = async (e) => {
    const now = new Date();
    const localDob = localState.dob
      ? localState.dob.split("/")
      : [1, 1, now.getFullYear()];
    switch (e.target.name) {
      case "zip":
        setLocalState({
          ...localState,
          [e.target.name]: e.target.value,
        });
        setZipLength(e.target.value.length);
        break;
      case "dobMonth":
        setLocalState({
          ...localState,
          dob: `${e.target.value}/${localDob[1]}/${localDob[2]}`,
        });
        break;
      case "dobDate":
        setLocalState({
          ...localState,
          dob: `${localDob[0]}/${e.target.value}/${localDob[2]}`,
        });
        break;
      case "dobYear":
        setLocalState({
          ...localState,
          dob: `${localDob[0]}/${localDob[1]}/${e.target.value}`,
        });
        break;
      default:
        setLocalState({
          ...localState,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  const handleAgeBlur = () => {
    if (localState.dob.length < 8) {
      setAgeError("Date of birth must be in valid format");
      return;
    }
    ageCheck();
  };

  const ageCheck = () => {
    const now = new Date();
    const eighteenYearsAgo = new Date(
      now.getFullYear() - 18,
      now.getMonth(),
      now.getDate()
    );
    const tooOld = new Date(
      now.getFullYear() - 110,
      now.getMonth(),
      now.getDate()
    );
    const dobArray = localState.dob
      ? localState.dob.split("/")
      : [1, 1, now.getFullYear()];
    const dobMonth = dobArray[0];
    const dobDate = dobArray[1];
    const dobYear = dobArray[2];
    const dob = new Date(dobYear, dobMonth - 1, dobDate);
    if (dob > eighteenYearsAgo) {
      setAgeError(
        "This service is only available for people over the age of 18."
      );
      setValidated(false);
      return false;
    } else if (dob < tooOld) {
      setAgeError("Please enter a valid date of birth");
      setValidated(false);
      return false;
    } else {
      setAgeError("");
      return true;
    }
  };

  const handleZipBlur = () => {
    if (zipLength < 5) {
      setZipError("Please enter a valid zip code");
    } else {
      setZipError("");
    }
  };

  const handleEmailBlur = () => {
    const regex = /[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+/;
    const regMatch = regex.test(localState.email);
    if (!regMatch) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  useEffect(() => {
    if (
      localState.firstName.length &&
      localState.lastName.length &&
      localState.email.length &&
      localState.phone.length &&
      localState.address.length &&
      localState.city.length &&
      localState.state.length &&
      localState.zip.length &&
      localState.dob.length >= 8 &&
      !ageError &&
      !zipError &&
      !emailError &&
      isPhoneValidated
    ) {
      if (!isPhoneVerified) {
        setValidated(false);
        return;
      }
      setValidated(true);
      onFormValidated(localState);
    } else {
      setValidated(false);
    }
  }, [
    localState,
    ageError,
    zipError,
    emailError,
    onFormValidated,
    isPhoneValidated,
    isPhoneVerified,
    setValidated,
  ]);

  useEffect(() => {
    headerRef.current.scrollIntoView();
  }, []);

  return (
    <div className="demographicContainer">
      <div className="checkoutForm">
        <div>
          <div className="dataSecureText">
            {dataSecure.header} For more information, click{" "}
            <a
              href="https://customerconsents.s3.amazonaws.com/Beluga_Health_PA_Privacy_Policy.pdf"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </div>
        </div>

        <div className="demographicSectionLabel">Personal Information</div>

        <div className="demographicSubRow firstAndLastName">
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">First name</div>
            <input
              name="firstName"
              placeholder="First Name"
              onChange={handleChange}
              className="checkoutInput"
            />
          </div>
          <div className="spacer" />
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">Last name</div>
            <input
              name="lastName"
              placeholder="Last Name"
              onChange={handleChange}
              className="checkoutInput"
            />
          </div>
        </div>
        <div className="demographicSubRow emailAndPhone">
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">Email</div>
            <input
              name="email"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleEmailBlur}
              className={
                emailError
                  ? "checkoutInput checkoutInputError"
                  : "checkoutInput"
              }
            />
            {emailError && <div className="checkoutError">{emailError}</div>}
          </div>
          <div className="spacer" />
          <PhoneVerificationInput
            localState={localState}
            setLocalState={setLocalState}
            isPhoneValidated={isPhoneValidated}
            setIsPhoneValidated={setIsPhoneValidated}
            isPhoneVerified={isPhoneVerified}
            setIsPhoneVerified={setIsPhoneVerified}
          />
          {/* <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">
              Mobile phone number
            </div>
            <input
              name="phone"
              placeholder="Phone"
              type="tel"
              onChange={handleChange}
              // maxLength='10'
              className={
                phoneError
                  ? "checkoutInput checkoutInputError"
                  : "checkoutInput"
              }
            />
            {phoneError && <div className="checkoutError">{phoneError}</div>}
          </div> */}
        </div>
        <div className="demographicSubRow dobAndAddress">
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">Date of birth</div>
            <div className="dobInputs" data-testid="dob">
              <input
                name="dobMonth"
                placeholder="MM"
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? "checkoutInputMini checkoutInputError"
                    : "checkoutInputMini"
                }
                maxLength="2"
              />
              <span className="expirySlash">/</span>
              <input
                name="dobDate"
                placeholder="DD"
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? "checkoutInputMini checkoutInputError"
                    : "checkoutInputMini"
                }
                maxLength="2"
              />
              <span className="expirySlash">/</span>
              <input
                name="dobYear"
                placeholder="YYYY"
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? "checkoutInputShort checkoutInputError"
                    : "checkoutInputShort"
                }
                maxLength="4"
              />
            </div>
            {ageError && <div className="checkoutError">{ageError}</div>}
          </div>
          <div className="spacer" />
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">Address</div>
            <input
              name="address"
              placeholder="Address"
              onChange={handleChange}
              className="checkoutInput"
            />
          </div>
        </div>
        <div className="demographicSubRow addressRow">
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">City</div>
            <input
              name="city"
              placeholder="City"
              onChange={handleChange}
              className="checkoutInput"
            />
          </div>
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">State</div>
            <div className="checkoutInputShort state" data-testid="state">
              <label>
                <select
                  name="state"
                  id="state-select"
                  data-testid="state-select"
                  onChange={handleChange}
                  required
                  defaultValue=""
                >
                  <option value="" disabled>
                    State
                  </option>
                  {statesFullNameList.map((name) => {
                    return (
                      <Fragment key={name}>
                        <option value={stateNameToAbbreviationMap[name]}>
                          {name}
                        </option>
                      </Fragment>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">Zip Code</div>
            <input
              name="zip"
              placeholder="Zip"
              onChange={handleChange}
              onBlur={handleZipBlur}
              className={
                zipError
                  ? "checkoutInputShort zip checkoutInputError"
                  : "checkoutInputShort zip"
              }
              maxLength="5"
            />
            {zipError && <div className="checkoutError">{zipError}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemographicForm;
