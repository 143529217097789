import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

import { apiKey } from "../config";
import { questionnaire } from "../DataFiles/formArrays";

const verifyPhone = async (phone, setPhoneError) => {
  try {
    const phoneResponse = await API.post("brains", "/external/phoneLookup", {
      credentials: "include",
      body: {
        phone,
        apiKey,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (phoneResponse.status !== 200) {
      setPhoneError("Please enter your cell phone number");
      return false;
    } else {
      setPhoneError("");
      return true;
    }
  } catch (err) {
    setPhoneError("Please enter your cell phone number");
    return false;
  }
};

const sendPhoneVerification = async (phone, setPhoneError) => {
  try {
    const phoneResponse = await API.post(
      "brains",
      "/external/sendVerification",
      {
        credentials: "include",
        body: {
          phone,
          apiKey,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (phoneResponse.status !== 200) {
      setPhoneError("Unable to send verification code");
      return false;
    } else {
      setPhoneError("");
      return true;
    }
  } catch (err) {
    setPhoneError("Unable to send verification code");
    return false;
  }
};

const verifyOTPForPhone = async (phone, code, setPhoneCodeError) => {
  try {
    const phoneResponse = await API.post("brains", "/external/verifyOTP", {
      credentials: "include",
      body: {
        phone,
        code,
        apiKey,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (phoneResponse.status !== 200) {
      setPhoneCodeError("The code you entered is invalid");
      return false;
    } else {
      setPhoneCodeError("");
      return true;
    }
  } catch (err) {
    setPhoneCodeError(
      "There was an error verifying your code. Please try again"
    );
    return false;
  }
};

let visitId = uuidv4();
const createVisit = async (
  formData,
  demographicData,
  setError,
  turnstileToken
) => {
  let stringifiedAnswers = formData;
  stringifiedAnswers = Object.keys(stringifiedAnswers).forEach((k) => {
    if (k === "patientPreference") {
      return;
    }
    if (Array.isArray(stringifiedAnswers[k])) {
      stringifiedAnswers[k] = stringifiedAnswers[k].join(";");
    }
  });
  const {
    allergies,
    medicalConditions,
    selfReportedMeds,
    sex,
    height,
    weight,
    dob,
    patientPreference,
    ...formRest
  } = formData;
  const { pharmacyId, ...rest } = demographicData;

  let cleanedUpPatientPreference;
  if (patientPreference?.length) {
    cleanedUpPatientPreference = [
      {
        medId: patientPreference[0].medId,
        name: patientPreference[0].name,
        strength: patientPreference[0].strength,
        refills: patientPreference[0].refills,
        quantity: patientPreference[0].quantity,
      },
    ];
  }

  const totalQuestions = questionnaire.length;
  const parsedWeight = parseInt(formData.weight);
  const parsedHeight = parseInt(formData.height);
  const calculatedBMI = (703 * (parsedWeight / parsedHeight ** 2)).toFixed(2);
  const bmi = {
    [`Q${totalQuestions}`]: "BMI",
    [`A${totalQuestions}`]: calculatedBMI,
  };
  // Note: follow up form does not send sex and dob data
  const body = {
    formObj: {
      ...rest,
      ...bmi,
      selfReportedMeds: selfReportedMeds || "None",
      allergies: allergies || "None",
      medicalConditions: medicalConditions || "None",
      patientPreference: cleanedUpPatientPreference || patientPreference,
      ...formRest,
    },
    company: "neweigh",
    visitType: "weightlossfollowup",
    apiKey,
    visitId,
  };

  try {
    const visitCreationResponse = await API.post("brains", "/form/neweigh", {
      credentials: "include",
      body,
      headers: {
        "Content-Type": "application/json",
        "X-Turnstile-Token": turnstileToken,
      },
    });
    if (visitCreationResponse.status !== 200) {
      if (visitCreationResponse.error === "State not valid") {
        setError(
          "Unfortunately we're not able to provide service in your state at this time. Please check back at a later date."
        );
      } else {
        setError(
          "Something went wrong, please check all your info and try again"
        );
      }
      return;
    }
    visitId = uuidv4();
    return visitCreationResponse.data.visitId;
  } catch (err) {
    setError("Something went wrong, please check all your info and try again.");
    return;
  }
};

const submitPhoto = async (currentVisitId, photosState, setError, navigate) => {
  const body = {
    visitId: currentVisitId,
    image: {
      mime: "image/jpeg",
      data: photosState.photo,
    },
    apiKey,
  };
  try {
    const photoResponse = await API.post("brains", "/external/receivePhoto", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    if (photoResponse.status !== 200) {
      setError("Something went wrong, please upload a photo and try again.");
      return;
    }
    navigate("/success");
  } catch (err) {
    setError("Something went wrong, please upload a photo and try again.");
    return;
  }
};

const submitMultiplePhotos = async (
  currentVisitId,
  photosState,
  setError,
  navigate
) => {
  const body = {
    visitId: currentVisitId,
    images: photosState.map((state) => {
      return {
        mime: "image/jpeg",
        data: state.photo,
      };
    }),
    apiKey,
  };
  try {
    const photoResponse = await API.post("brains", "/external/receivePhotos", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    if (photoResponse.status !== 200) {
      setError("Something went wrong, please upload a photo and try again.");
      return;
    }
    navigate("/success");
  } catch (err) {
    setError("Something went wrong, please upload a photo and try again.");
    return;
  }
};

export const routerMap = {
  verifyPhone,
  sendPhoneVerification,
  verifyOTPForPhone,
  createVisit,
  submitPhoto,
  submitMultiplePhotos,
};
