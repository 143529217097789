import React, { useState, useEffect } from "react";

import FormStep from "../FormStep/FormStep";
import SimpleSubmit from "../SimpleSubmit/SimpleSubmit";

import "./MainForm.css";

const MainForm = (props) => {
  const { formData, setFormData, questionnaire, page, setPage, visitId } = props;

  const [currentStep, setCurrentStep] = useState({
    formStep: questionnaire[page],
  });
  const [formStep, setFormStep] = useState(null);
  const [lastAnswerArray, setLastAnswerArray] = useState([]);
  const [validated, setValidated] = useState(false);

  const lastPage = questionnaire.length;
  const mostRecent = lastAnswerArray
    .slice()
    .reverse()
    .find((item) => item.page === page - 1);
  const canGoForward = page < lastPage;
  const canGoBack = page !== 0;

  // currentPage would be either "photo" or "submit" for now
  const [lastPageScreen, setLastPageScreen] = useState("submit");

  const onLastPageUpdate = (currentPage) => {
    setLastPageScreen(currentPage);
    // console.log('attempting to scroll to 0,0...')
    // window.scrollTo(0,0);
    // console.log('after scroll...')
  };

  const previousPage = () => {
    // here the flow is photo -> submit
    // so if we"re on submit we want to go back to photo instead
    // of the questionaire
    // if (lastPageScreen === "submit") {
    //   setLastPageScreen("photo");
    //   return;
    // }
    const lastNonConditional = questionnaire
      .slice(0, page)
      .reverse()
      .find((question) => !question.isConditional);
    const indexOf = questionnaire.indexOf(lastNonConditional);
    setPage(indexOf);
  };

  const nextPage = () => {
    const currentQuestion = questionnaire[page];
    const currentAnswer =
      currentQuestion.answers && formData[`A${page}`] && page !== lastPage
        ? currentQuestion.answers.filter((answer) =>
            formData[`A${page}`].includes(answer.answer)
          )
        : null;

    if (currentAnswer) {
      if (currentAnswer.some((item) => item.showConditional)) {
        setPage(page + 1);
        return;
      } else if (page + 1 < lastPage) {
        const nextNonConditional = questionnaire
          .slice(page + 1)
          .find((question) => !question.isConditional);

        const indexOf = questionnaire.indexOf(nextNonConditional);
        if (indexOf > 0) {
          setPage(indexOf);
          return;
        } else {
          setPage(lastPage);
        }
      } else {
        setPage(page + 1);
        return;
      }
    } else {
      setPage(page + 1);
      return;
    }
  };

  const renderFormStep = (currentStep, localAnswer) => {
    if (currentStep.formStep.question === "Metaquestion") {
      setFormStep(
        <FormStep
          questionnaire={questionnaire}
          currentStep={currentStep.formStep}
          isSub={true}
          localAnswer={localAnswer}
          formData={formData}
          setFormData={setFormData}
          page={page}
          lastPage={lastPage}
          lastAnswerArray={lastAnswerArray}
          setLastAnswerArray={setLastAnswerArray}
          setValidated={setValidated}
          mostRecent={mostRecent}
        />
      );
      return;
    }
    setFormStep(
      <FormStep
        questionnaire={questionnaire}
        currentStep={currentStep.formStep}
        localAnswer={localAnswer}
        key={page}
        formData={formData}
        setFormData={setFormData}
        page={page}
        lastPage={lastPage}
        lastAnswerArray={lastAnswerArray}
        setLastAnswerArray={setLastAnswerArray}
        setValidated={setValidated}
        mostRecent={mostRecent}
      />
    );
  };

  const formClass = canGoForward
    ? "formStyle formStyleWithContinue"
    : "formStyle";

  useEffect(() => {
    if (page < lastPage) {
      setCurrentStep({
        formStep: questionnaire[page],
      });
    }
  }, [page]);

  useEffect(() => {
    let effectAnswer = "";
    switch (currentStep.formStep.standardDemo) {
      case "allergies":
      case "selfReportedMeds":
      case "medicalConditions":
      case "sex":
      case "state":
        if (formData[`${currentStep.formStep.standardDemo}`]) {
          effectAnswer = formData[`${currentStep.formStep.standardDemo}`];
        } else {
          effectAnswer = "";
        }
        break;
      case "dob":
        if (formData.dob) {
          effectAnswer = {
            dobMonth: formData.dob.split("/")[0],
            dobDate: formData.dob.split("/")[1],
            dobYear: formData.dob.split("/")[2],
          };
        }
        break;
      default:
        effectAnswer = formData[`A${page}`] || "";
        break;
    }
    renderFormStep(currentStep, effectAnswer);
  }, [currentStep]);

  return (
    <div className="pageContainer">
      <div className="pageHeader">
        <div className="pageTitle"></div>
      </div>
      <div
        className={
          page === lastPage
            ? "contentContainer lastPageContainer"
            : "contentContainer"
        }
      >
        <div className="prevFormCont">
          <div className="previousButtonHolder">
            {canGoBack && (
              <button onClick={previousPage} className="previousButton">
                <div className="vectorArrow" />
              </button>
            )}
          </div>

          <div
            className={[
              "formAndContinue",
              page === lastPage ? "lastPage" : "",
            ].join(" ")}
          >
            {page !== lastPage && (
              <div className="formStep">
                {" "}
                STEP {page + 1} / {questionnaire.length}{" "}
              </div>
            )}
            {page < lastPage && <div className={formClass}>{formStep}</div>}
            {page === lastPage && (
              <SimpleSubmit
                visitId={visitId}
                formData={formData}
                currentScreen={lastPageScreen}
                onPageUpdate={onLastPageUpdate}
              />
            )}
            {canGoForward && (
              <button
                onClick={nextPage}
                disabled={!validated}
                className="orderButton"
              >
                Continue
              </button>
            )}
          </div>
        </div>
      </div>
      {/* {page !== lastPage && <div className="pageFooter"></div>} */}
    </div>
  );
};

export default MainForm;
