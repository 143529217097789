export const patientPreferenceOptions = [
  {
    medication: "Tirzepatide",
    options: [
      {
        name: "Tirzepatide 2.5mg",
        id: "nfAFw635atdT79W0rlDbFWO4sSOMnXJw",
        quantity: 1,
        dispense: "ml",
        refills: 0,
        strength: "2.5 MG",
      },
      {
        name: "Tirzepatide 5mg",
        id: "VjyGELD8XXZ2MokmUYBaOxnYdVwMNefT",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "5 MG",
      },
      {
        name: "Tirzepatide 7.5mg",
        id: "CoR8fkPUgTTZDK72IK4NCa0BvI5plYMq",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "7.5 MG",
      },
      {
        name: "Tirzepatide 10mg",
        id: "Wd1BQnNnvuyLyCm3RQ3PWJxIzDMSthIo",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "10 MG",
      },
      {
        name: "Tirzepatide 12.5mg",
        id: "zWKeo4JOvaTfH8aqvqFQbi9dkeNKh13A",
        quantity: 2.5,
        dispense: "ml",
        refills: 0,
        strength: "12.5 MG",
      },
      {
        name: "Tirzepatide 15mg",
        id: "YHVcXLA2Z9MiJkwcc7v0NnBgcLNxo5Kg",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "15 MG",
      },
    ],
  },
];

const patientPreferenceChoices = patientPreferenceOptions.reduce(
  (prev, cur) => {
    const group = cur.medication;
    const options = cur.options;
    prev.push({
      answer: {
        isText: true,
        value: group,
      },
    });
    options.forEach((opt) => {
      if (opt.isRecommended) {
        prev.push({
          answer: {
            displayName: "Let the doctor recommend",
            name: "Let the doctor recommend",
            group,
            optionId: opt.id,
            optionData: opt,
          },
        });
      } else {
        prev.push({
          answer: {
            name: `${opt.name} ${opt.quantity} ${opt.dispense} - (Refills - ${opt.refills})`,
            displayName: opt.name,
            group,
            optionId: opt.id,
            optionData: opt,
          },
        });
      }
    });
    return prev;
  },
  []
);export const questionnaire = [
  {
    question: "How are you doing on the current medication?",
    answers: [
      {
        answer: "Well",
        showConditional: false,
      },
      {
        answer: "Not well",
        showConditional: true,
      },
    ],
    type: "radio",
  },
  {
    question: "Please tell us more about what you’re experiencing",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question: "Current Height",
    subText: "Height in inches",
    answers: ["number"],
    type: "number",
    standardDemo: "height",
    isConditional: false,
  },
  {
    question: "Current Weight",
    subText: "Weight in pounds (lbs)",
    answers: ["number"],
    type: "number",
    standardDemo: "weight",
    isConditional: false,
  },
  {
    question: "Have you had any side effects from the current medication?",
    answers: [
      {
        answer: "No",
        showConditional: false,
      },
      {
        answer: "Yes",
        showConditional: true,
      },
    ],
    type: "radio",
  },
  {
    question: "Please tell us more about the side effects you are experiencing",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question: "Are you ready to increase your dose or stay at the same dose?",
    answers: [
      {
        answer: "Increase",
        showConditional: false,
      },
      {
        answer: "Stay at the same dose",
        showConditional: false,
      },
      {
        answer: "Stop the medication",
        showConditional: false,
      },
    ],
    type: "radio",
  },
  {
    question: "Anything else you’d like to share with your doctor?",
    answers: ["freeText"],
    type: "freeText",
    isConditional: false,
  },
  {
    question: "Please Select An Option",
    type: "radio",
    answers: patientPreferenceChoices,
    standardDemo: "patientPreference",
  },
];
